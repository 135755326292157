


























































@import '@design';
@import '@styleMixins';

.v-card.hero-cta {
  background-color: $color-db-gold;
}
.hero-title {
  // TODO (ESS) : dont hard code
  @include font-size(91px, 0.55, 1, 1, 1);

  padding-bottom: 0.15em;
  margin: 0 0 0.3em;
  line-height: 0.8 !important;
  letter-spacing: 0.024em;
  border-bottom: 2px solid $color-db-purple;
}

.hero-text {
  // TODO (ESS) : dont hard code
  margin-bottom: 1em;
  font-size: 2rem;
  line-height: 1.4;

  @media only screen and (max-width: 414px) {
    font-size: 1.3rem;
  }
}
